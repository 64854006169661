var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.title,
        width: 800,
        ok: false,
        visible: _vm.visible,
        confirmLoading: _vm.confirmLoading,
        okButtonProps: { props: { disabled: _vm.disableSubmit } },
        cancelText: "关闭",
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
    },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.confirmLoading } },
        [
          _c(
            "a-form",
            { attrs: { form: _vm.form } },
            [
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "机构名称",
                    hidden: false,
                    hasFeedback: "",
                  },
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["departName", _vm.validatorRules.departName],
                        expression:
                          "['departName', validatorRules.departName ]",
                      },
                    ],
                    attrs: {
                      id: "departName",
                      placeholder: "请输入机构/部门名称",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    hidden: _vm.seen,
                    label: "上级部门",
                    hasFeedback: "",
                  },
                },
                [
                  _c("a-tree-select", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      dropdownStyle: { maxHeight: "200px", overflow: "auto" },
                      treeData: _vm.departTree,
                      placeholder: "请选择上级部门",
                      disabled: _vm.condition,
                    },
                    model: {
                      value: _vm.model.parentId,
                      callback: function ($$v) {
                        _vm.$set(_vm.model, "parentId", $$v)
                      },
                      expression: "model.parentId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "机构类型",
                  },
                },
                [
                  _vm.seen
                    ? [
                        _c(
                          "a-radio-group",
                          {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "orgCategory",
                                  _vm.validatorRules.orgCategory,
                                ],
                                expression:
                                  "['orgCategory',validatorRules.orgCategory]",
                              },
                            ],
                            attrs: { placeholder: "请选择机构类型" },
                          },
                          [
                            _c("a-radio", { attrs: { value: "1" } }, [
                              _vm._v("\n            公司\n          "),
                            ]),
                          ],
                          1
                        ),
                      ]
                    : [
                        _c(
                          "a-radio-group",
                          {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "orgCategory",
                                  _vm.validatorRules.orgCategory,
                                ],
                                expression:
                                  "['orgCategory',validatorRules.orgCategory]",
                              },
                            ],
                            attrs: { placeholder: "请选择机构类型" },
                          },
                          [
                            _c("a-radio", { attrs: { value: "2" } }, [
                              _vm._v("\n              部门\n            "),
                            ]),
                            _c("a-radio", { attrs: { value: "3" } }, [
                              _vm._v("\n              岗位\n            "),
                            ]),
                          ],
                          1
                        ),
                      ],
                ],
                2
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "电话",
                  },
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["mobile", _vm.validatorRules.mobile],
                        expression: "['mobile',validatorRules.mobile]",
                      },
                    ],
                    attrs: { placeholder: "请输入电话" },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "传真",
                  },
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["fax", {}],
                        expression: "['fax', {}]",
                      },
                    ],
                    attrs: { placeholder: "请输入传真" },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "地址",
                  },
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["address", {}],
                        expression: "['address', {}]",
                      },
                    ],
                    attrs: { placeholder: "请输入地址" },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "排序",
                  },
                },
                [
                  _c("a-input-number", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["departOrder", { initialValue: 0 }],
                        expression: "[ 'departOrder',{'initialValue':0}]",
                      },
                    ],
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "备注",
                  },
                },
                [
                  _c("a-textarea", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["memo", {}],
                        expression: "['memo', {}]",
                      },
                    ],
                    attrs: { placeholder: "请输入备注" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }